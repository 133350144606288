import type { ForwardedRef, MouseEventHandler } from 'react';
import { forwardRef } from 'react';

import { classFilter } from '@shieldpay/theme-provider-ui/utilities';

import type {
  ButtonOrLinkComponentProps,
  LinkOrButton,
} from '../../styles/clickable-element/clickable-element';
import {
  getClickableElementStyles,
  isValidIconButton,
  isValidLoadingState,
} from '../../styles/clickable-element/clickable-element';
import type { BoxProps } from '../box/box';
import { Box } from '../box/box';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';
import { ScreenReaderText } from '../screen-reader-text/screen-reader-text';
import { Text } from '../text/text';

export type ButtonProps = Pick<BoxProps, 'id' | 'value' | 'className'> &
  ButtonOrLinkComponentProps & {
    type?: HTMLButtonElement['type'];
    form?: string;
    formNoValidate?: boolean;
    onClick?: MouseEventHandler;
    'data-testid'?: string;
  };

export const TEST_IDS = {
  LEFT_ICON: 'left-icon',
  RIGHT_ICON: 'right-icon',
};

/**
 * Do not use the ButtonWithoutForwardRef export in the platform, use the Button export instead.
 *
 * This export is used to generate documentation for Hive only.
 */
export const ButtonWithoutForwardRef = (
  {
    size = 'medium',
    'data-testid': testId,
    onClick,
    children,
    variant = 'solid',
    type = 'button',
    disabled,
    loading = false,
    LeftIcon,
    RightIcon,
    Icon,
    iconColor,
    className,
    ...buttonProps
  }: ButtonProps,
  ref?: ForwardedRef<HTMLButtonElement>,
) => {
  const {
    styles,
    paddingX,
    paddingY,
    textVariant,
    iconSize,
    spacing,
    resetStyles,
  } = getClickableElementStyles({
    ...({ size, variant } as LinkOrButton),
    disabled,
    hasLeftIcon: !!LeftIcon,
    hasRightIcon: !!RightIcon || loading,
  });

  return (
    <Box
      disabled={loading || disabled}
      Component="button"
      variant="initial" // css display property set by getClickableElementStyles
      className={classFilter([resetStyles, styles, className])}
      onClick={onClick}
      data-testid={testId}
      type={type}
      ref={ref}
      padding={{ ...paddingX, y: 'none' }}
      spacing={spacing}
      rounded
      {...buttonProps}
    >
      {LeftIcon ? (
        <LeftIcon data-testid={TEST_IDS.LEFT_ICON} size={iconSize} />
      ) : null}
      {isValidIconButton(variant, Icon) ? (
        <Box padding={paddingY}>
          <ScreenReaderText>{children}</ScreenReaderText>
          <Icon size={iconSize} color={iconColor} />
        </Box>
      ) : (
        <Text color="currentColor" padding={paddingY} variant={textVariant}>
          {children}
        </Text>
      )}

      {RightIcon ? (
        <RightIcon data-testid={TEST_IDS.RIGHT_ICON} size={iconSize} />
      ) : null}

      {isValidLoadingState(loading, size) ? (
        <LoadingSpinner
          size={size}
          color={variant === 'solid' ? 'white' : 'primary'}
        />
      ) : null}
    </Box>
  );
};

// Manually reset the displayName in the component tree.
ButtonWithoutForwardRef.displayName = 'Button';

export const Button = forwardRef(ButtonWithoutForwardRef);
